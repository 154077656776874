import $ from 'jquery';
window.$ = window.jQuery = $;

import arrowUp from '../images/general/icon-arrow-up.svg';
import arrowDown from '../images/general/icon-arrow-down.svg';
import imagePlaceholder from '../images/general/no-image.png';

$(window).on("load", function () {

  //Mecanismo de ajuste da escala da obra
  const scaleContainer = $('.scale-container')
  const workArea = scaleContainer.find('.work-area');
  const humanScaleContainer = scaleContainer.find('.human-scale')
  const humanScale = humanScaleContainer.find('img');
  const humanHeight = 170;
  if (workArea) {
    calculateScale();
  }

  function calculateScale() {

    //Obtem largura e altura real da obra em cm
    let workWidthCm = workArea.attr('width');
    let workHeightCm = workArea.attr('height');

    //Calcula razão entre altura e largura
    let workRatio = workWidthCm / workHeightCm;
    //Calcula razão do container
    let containerRatio = scaleContainer.width() / scaleContainer.height();

    //Obtem a altura em pixels da escala, que pode variar de acordo com o tamanho da tela
    let scaleHeightPx = humanScale.height();

    //Calcula a largura e altura da obra em pixels, de acordo com as dimensões em pixels da escala
    let workHeightPx = (workHeightCm * scaleHeightPx) / humanHeight;
    let workWidthPx = workHeightPx * workRatio;

    //Checa se a imagem é maior que o container
    if (workWidthPx > scaleContainer.width() - 40 || workHeightPx > scaleContainer.height() - 40) {

      //Checa se imagem é quadrada ou horizontal
      if (workRatio > 1) {

        //Ajusta a largura para caber no container
        workArea.width(scaleContainer.width() - 40);
        workArea.height(workArea.width() / workRatio);

      } else {

        //Ajusta altura para caber no container
        workArea.height(scaleContainer.height() - 40);
        workArea.width(workArea.height() * workRatio);

      }

      //Ajusta a escala humana para manter a proporção
      let humanScaleHeight = (humanHeight * workArea.height()) / workHeightCm;

      if (humanScaleHeight < humanScaleContainer.height()) {
        humanScale.css('margin-top', humanScaleContainer.height() - humanScaleHeight + 20);
      }
      humanScale.height(humanScaleHeight);

    } else {

      workArea.width(workWidthPx);
      workArea.height(workHeightPx);
      humanScale.css('margin-top', '120px');
      scaleContainer.css('margin-top', '-50px');

    }

  }

  $(window).on('resize', function () {
    if (workArea) {
      calculateScale();
    }
  });

});

$(document).ready(function () {

  //Abre e fecha os blocos dos módulos dentro do modal de filtros
  const filterBtn = $('.filter .title');
  filterBtn.on("click", function () {
    let options = $(this).parent().find('.options');
    options.toggle();
    if (options.is(':visible')) {
      $(this).find('img').attr('src', arrowUp);
    } else {
      $(this).find('img').attr('src', arrowDown);
    }
  });

  //Altera entre visualização de grid e lista
  const toggleView = $('.btn-toggle-view');
  toggleView.on("click", function () {
    toggleView.removeClass('active');
    $(this).addClass('active');
    let mainContainer = $(this).parents().find('main');
    let wrap = mainContainer.find('section.catalog.list .articles-wrap');
    let articles = wrap.find('article');
    let thumbnail = articles.find('.thumbnail');
    if ($(this).hasClass('list-view')) {
      mainContainer.removeClass('container-flex').addClass('container');
      mainContainer.find('header').removeClass('container');
      mainContainer.find('nav').removeClass('container');
      wrap.removeClass('card-columns').removeClass('container-flex').addClass('container');
      articles.removeClass('card');
      articles.find('.content').show();
      thumbnail.find('.grid-content').addClass('d-none');
      thumbnail.find('.list-content').removeClass('d-none');
    } else {
      mainContainer.removeClass('container').addClass('container-flex');
      mainContainer.find('header').addClass('container');
      mainContainer.find('nav').addClass('container');
      wrap.addClass('card-columns').addClass('container-flex').removeClass('container');
      articles.addClass('card');
      articles.find('.content').hide();
      thumbnail.find('.grid-content').removeClass('d-none');
      thumbnail.find('.list-content').addClass('d-none');
    }
  });

  //Ações dos botões da sidebar/appbar
  let sidebarTabBtn = $("#sidebar ul li:not([data-external-link])");
  let tabs = $('#tabs');
  let activeTab = '';
  const body = $('body');

  sidebarTabBtn.on("click", function () {
    tab($(this));
  });

  //Ação do botão de busca da home
  $('button.btn-search').on("click", function () {
    tab($('#sidebar ul li.filter'));
  });

  //Ação do botão de busca da home
  $('footer .highlights .contact').on("click", function () {
    tab($('#sidebar ul li.contact'));
  });

  //Método para dinâmica de abertura e fechamento das abas da sidebar/appbar
  function tab(targetTab) {
    sidebarTabBtn.css('background-color', '');
    sidebarTabBtn.find('.close').addClass('d-none');

    if (activeTab === targetTab.attr('class')) {
      tabs.find('.' + activeTab).addClass('d-none');
      tabs.addClass('d-none');
      activeTab = '';
      body.css('overflow', 'auto');
    } else {
      body.css('overflow', 'hidden');
      targetTab.css('background-color', '#fff');
      targetTab.find('.close').removeClass('d-none');
      if (activeTab !== '') {
        tabs.find('.' + activeTab).addClass('d-none');
      }
      tabs.removeClass('d-none').find('.' + targetTab.attr('class')).removeClass('d-none');
      activeTab = targetTab.attr('class');
    }
  }

  //Configurações do plugin slick para rolagem da área de novidades da home
  $('.discoveries .articles-wrap').slick({
    prevArrow: $('.nav-disc-left'),
    nextArrow: $('.nav-disc-right'),
    infinite: true,
    centerMode: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  //Configurações do plugin slick para rolagem da área de mídia da home
  $('section.medias .medias-wrap').slick({
    arrows: true,
    infinite: true,
    prevArrow: $('section.medias .nav-media-left'),
    nextArrow: $('section.medias .nav-media-right'),
  });

  // Dinâmica de abertura e fechamento dos módulos de conteúdos nas telas de detalhe
  $(".card-sections section header:not([data-collapsible-disable])").on("click", function () {
    let module = $(this);
    let content = module.parent().find('.content');
    if (content.hasClass('d-none')) {
      module.removeClass('border-top').addClass('border-top-5');
      content.removeClass('d-none');
      $(this).find('img').attr('src', arrowUp).attr('title', 'Fechar módulo');
    } else {
      if (module.parent().index()) {
        module.removeClass('border-top-5').addClass('border-top');
      }
      content.addClass('d-none');
      $(this).find('img').attr('src', arrowDown).attr('title', 'Abrir módulo');
    }
  });

  // Dinâmica de abertura e fechamento dos módulos de conteúdos filhos nas telas de detalhe
  $('.card-sections section header.border-top-1:not([data-collapsible-disable])').on("click", function () {
    let module = $(this);
    let content = module.parent().find('.content-child');
    if (content.hasClass('d-none')) {
      module.removeClass('border-top-1').addClass('border-top-5');
      content.removeClass('d-none');
      $(this).find('img').attr('src', arrowUp).attr('title', 'Fechar módulo');
    } else {
      if (module.parent().index()) {
        module.removeClass('border-top-5').addClass('border-top-1');
      }
      content.addClass('d-none');
      $(this).find('img').attr('src', arrowDown).attr('title', 'Abrir módulo');
    }
  });

  //Ações referentes a barra de compartilhamento
  $('.btn-print').on("click", function () {
    window.print();
  });

  //Ações referentes a barra de pesquisa
  $('.research--link').on("click", function () {
    $('#research').fadeOut();
  });

  // Thumbnail image missing fallback
  $('.thumbnail img').on('error', function () {
    $(this).attr('src', imagePlaceholder);
  });
});
