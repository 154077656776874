import $ from "jquery";

$(() => {

  // XXX: só exibe popup na "home"
  if (window.location.pathname != "/") return;


  const POPUP_CLOSED_KEY = "__enciclopedia-ic:verbeth-popup:closed"

  var disabled = localStorage && (localStorage.getItem(POPUP_CLOSED_KEY) == "1")

  // se já fechou o popup, não exiba-o novamente
  if (disabled) return


  var popup = document.getElementById("verbeth-popup");
  var popupClose = document.getElementById("verbeth-popup-close");
  var popupArrow = document.getElementById("verbeth-popup-arrow");

  // utils
  // ---

  // Fecha popup ao clicar no "x"
  function closePopup(event) {
    $(popup).addClass("display-none")

    localStorage.setItem(POPUP_CLOSED_KEY, "1")
  };

  // Ajusta popup para menu posicionado na parte inferior
  function adjustPopupPosition() {
    // se for tela de disposivo smartphone - sidebar/navbar no canto inferior da tela
    if (window.visualViewport.width < 575) {
      popup.style.top = "-95px";
      popup.style.left = "-103px";
      popupArrow.className = "verbeth-arrow-down";
    } else {
      // senão, é tela de dispositivo desktop - sidebar no canto esquerdo da tela
      popup.style.top = "-5px";
      popup.style.left = "49px";
      popupArrow.className = "verbeth-arrow-left";
    }
  }


  // run
  // ---

  adjustPopupPosition()
  $(popup).removeClass("display-none")

  // ajusta posição do popup de acordo com o tamanho da tela
  window.addEventListener("resize", adjustPopupPosition)

  // fecha popup ao clicar no "x"
  popupClose.addEventListener("click", closePopup)

})
